import { FC } from 'react';
import cn from 'classnames';

type Props = {
  currentColor: string | null;
  colors: string[];
  setColor: (color: string) => void;
};

export const ColorsLayout: FC<Props> = ({ currentColor, colors = [], setColor }) => (
  <>
    {colors.map((color) => (
      <div
        key={color}
        className={cn('h-3.5 w-3.5 rounded-full border border-gray-500 cursor-pointer', {
          'primary-color-border border-2': color === currentColor,
          'transition hover:opacity-70': color !== currentColor,
        })}
        style={{ backgroundColor: color }}
        onClick={() => setColor(color)}
      />
    ))}
  </>
);
