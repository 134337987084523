import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import { BodyType } from '@/types';

type Props = {
  currentType: string | null;
  bodyType: BodyType[];
  setType: (type: BodyType) => void;
};

export const TypesLayout: FC<Props> = ({ currentType, bodyType = [], setType }) => {
  const { t } = useTranslation();

  return (
    <>
      {bodyType.map((type) => (
        <span
          key={type}
          className={cn('border-b border-black border-dashed capitalize cursor-pointer uppercase', {
            'primary-color-border primary-color-text': type === currentType,
            'transition hover:opacity-70': type !== currentType,
          })}
          onClick={() => setType(type)}>
          {t(`bodyType.${type}`)}
        </span>
      ))}
    </>
  );
};
