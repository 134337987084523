import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { api } from '@/api';
import { QueryKey } from '@/constants/api';
import {
  Car,
  CarCalendar,
  CarFilters,
  ExtraServiceType,
  LocaleType,
  PlaceType,
  PriceData,
  ServerExtraService,
} from '@/types';

export const useGetCars = ({ locale }: { locale: LocaleType }) => {
  return useQuery<AxiosResponse<Car[]>, Error>([QueryKey.CARS, locale], () => api.cars.getCars({ locale }));
};

export const useGetCarFilters = () => {
  return useQuery<CarFilters, Error>([QueryKey.CAR_FILTERS], () => api.cars.getCarFilters());
};

export const useGetCar = (id: string, locale: LocaleType) => {
  return useQuery<Car, Error>([QueryKey.CAR, id, locale], () => api.cars.getCar(id, locale));
};

export const useGetCarCalendar = ({
  carId,
  startDate,
  endDate,
}: {
  carId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery<AxiosResponse<CarCalendar[]>, Error>([QueryKey.CAR_CALENDAR, carId, startDate, endDate], () =>
    api.cars.getCarAvailabilityCalendar({ carId, startDate, endDate })
  );
};

export const useGetCarForRent = ({
  startDate,
  startTime,
  endDate,
  endTime,
  color,
  bodyType,
  locale,
  currency,
}: {
  startDate?: string | null;
  startTime?: string | null;
  endDate?: string | null;
  endTime?: string | null;
  color?: string | null;
  bodyType?: string | null;
  locale?: LocaleType | null;
  currency?: string | null;
}) => {
  return useQuery<AxiosResponse<Car[]>, Error>(
    [QueryKey.CARS_FOR_RENT, startDate, startTime, endDate, endTime, color, bodyType, locale, currency],
    () =>
      api.cars.getCarsForRent({
        startDate,
        startTime,
        endDate,
        endTime,
        color,
        bodyType,
        locale,
        currency,
      })
  );
};

export const useGetExtraServices = ({ locale }: { locale: LocaleType }) => {
  return useQuery<AxiosResponse<ExtraServiceType[]>, Error>([QueryKey.CARS, locale], () =>
    api.cars.getExtraServicesInfo({ locale })
  );
};

export const useGetCalculateCost = ({
  startDate,
  startTime,
  endDate,
  endTime,
  carId,
  addressToDeliverGeo,
  addressToPickupGeo,
  extraServices,
}: {
  startDate?: string | null;
  startTime?: string | null;
  endDate?: string | null;
  endTime?: string | null;
  carId?: string | null;
  addressToDeliverGeo?: PlaceType | null;
  addressToPickupGeo?: PlaceType | null;
  extraServices?: { [key: string]: ServerExtraService } | null;
}) => {
  return useQuery<AxiosResponse<PriceData>, Error>(
    [
      QueryKey.CARS_CALC,
      startDate,
      startTime,
      endDate,
      endTime,
      carId,
      addressToDeliverGeo,
      addressToPickupGeo,
      extraServices,
    ],
    () =>
      api.cars.calculateRentCost({
        startDate,
        startTime,
        endDate,
        endTime,
        carId,
        addressToDeliverGeo,
        addressToPickupGeo,
        extraServices,
      })
  );
};
