import { FC } from 'react';
import { useTranslation } from 'next-i18next';

type Props = {
  refetch: VoidFunction;
};

export const ErrorWidget: FC<Props> = ({ refetch }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-end justify-center text-center sm:block">
      <div
        // eslint-disable-next-line max-len
        className="inline-block align-bottom border border-[#EE1515] bg-[#FFF4F3] rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog">
        <div className="mx-auto flex items-center justify-center h-12 w-12">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#EE1515" />
            <path d="M9 17.8389L17.8388 9.00003" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M17.8389 17.8389L9.00003 9.00003" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        <div className="mt-2 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            {t('loadingDataError')}
          </h3>
        </div>
        <div className="mt-3">
          <span
            onClick={refetch}
            className="cursor-pointer primary-color-text inline-flex justify-center w-full text-base font-medium">
            {t('repeat')}
          </span>
        </div>
      </div>
    </div>
  );
};
