import { useQuery } from 'react-query';

import { api } from '@/api';
import { QueryKey } from '@/constants/api';
import { LocaleType, SeoData } from '@/types';

export const useGetSeoData = ({ slug, locale, carId }: { slug?: string; locale?: LocaleType; carId?: string }) => {
  return useQuery<SeoData, Error>([QueryKey.SEO_DATA, slug, locale, carId], () =>
    api.seo.getSeoPageData({ slug, locale, carId })
  );
};
